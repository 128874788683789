import React from 'react'
import { RecoilRoot } from 'recoil'
import GoTop from './GoTop'
import SEO from './SEO'
import CookieConsent from "react-cookie-consent"
import {LiveChatWidget} from "@livechat/widget-react";

const Layout = ({ children }) => {
    return (
        <RecoilRoot>
            {children}
            <GoTop scrollStepInPx="100" delayInMs="0" />
            <LiveChatWidget license="14692527" />
        </RecoilRoot>
    )
}

export default Layout
