import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo-alb.png"
import footerMap from "../../assets/images/footer-map.png"
import anpcImg from "../../assets/images/footer/anpc-sal.png"
import litigiiImg from "../../assets/images/footer/anpc-sol.png"
import "../../assets/css/Footer/footer.style.css"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" loading="lazy" />
              </a>

              <ul className="footer-links-list">
                <li>
                  <a href="mailto:contact@sitebunker.ro">
                    contact@sitebunker.ro
                  </a>
                </li>
                <li>
                  <a href="tel:0377101459">0377 101 459</a>
                </li>
                <li>
                  <p>
                    <strong>Program Telefonic:</strong>
                    <br /> Luni-Vineri 9:00-17:00
                    <br /> exceptând sărbătorile legale
                  </p>
                </li>
                <li>
                  <p>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/sitebunker.ro/"
                    >
                      <i className="bx bxl-facebook" /> Facebook
                    </a>
                  </p>
                </li>

                <li>
                  <p>
                    <a target="_blank" href="https://anpc.ro/ce-este-sal/">
                      <img src={litigiiImg} alt="Litigii" />
                    </a>
                  </p>
                </li>

                <li>
                  <p>
                    <a
                      target="_blank"
                      href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
                    >
                      <img src={anpcImg} alt="ANPC" />
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Găzduire avansată</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/gazduire-web-ssd/">Găzduire Web SSD</Link>
                </li>
                <li>
                  <Link to="/gazduire-wordpress-ssd/">
                    Găzduire WordPress SSD
                  </Link>
                </li>
                <li>
                  <Link to="/gazduire-prestashop-ssd/">
                    Găzduire PrestaShop SSD
                  </Link>
                </li>
                <li>
                  <Link to="/gazduire-magento-ssd/">Găzduire Magento SSD</Link>
                </li>
                <li>
                  <Link to="/gazduire-reseller-ssd/">
                    Găzduire Reseller SSD
                  </Link>
                </li>
              </ul>
            </div>

            <div className="single-footer-widget pl-5">
              <h3>Domenii</h3>

              <ul className="footer-links-list">
                <li>
                  <a
                    href="https://sitebunker.ro/client/cart.php?a=add&domain=register&language=romanian&currency=2"
                    target="_blank"
                  >
                    Înregistrare Domenii
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Servere</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/servere-dedicate/">Servere Dedicate</Link>
                </li>
                <li>
                  <Link to="/servere-vps/">Servere VPS</Link>
                </li>
              </ul>
            </div>

            <div className="single-footer-widget pl-5">
              <h3>Link-uri utile</h3>
              <ul className="footer-links-list">
                <li>
                  <Link to="/politica-de-confidentialitate/">
                    Politică de confidențialitate
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-service/">Termeni si Condiții</Link>
                </li>
                <li>
                  <Link to="/angajament-de-calitate-sla/">
                    Angajament de Calitate - SLA
                  </Link>
                </li>
                <li>
                  <a href="http://www.anpc.gov.ro/" target="_blank">
                    A.N.P.C
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            {/*<div className="single-footer-widget pl-5">*/}
            {/*  <h3>Licențe</h3>*/}

            {/*  <ul className="footer-links-list">*/}
            {/*    <li>*/}
            {/*      <Link to="#">Licența cPanel/WHM</Link>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <Link to="#">Licența CloudLinux</Link>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}

            <div className="single-footer-widget pl-5">
              <h3>Informații</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/afiliere/">Afiliere</Link>
                </li>
                <li>
                  <Link to="/achizitii-seap/">Achizitii SEAP</Link>
                </li>
                <li>
                  <a
                    href="https://sitebunker.ro/client/submitticket.php?language=romanian&currency=2"
                    target="_blank"
                  >
                    Support
                  </a>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="single-footer-widget pl-5">
              <p className="small d-flex flex-row flex-wrap">
                <span>*Prețurile nu includ TVA.</span>
                <span>
                  Garanția returnarii banilor în 14 zile nu se aplică domeniilor
                </span>
                <span>1 GB = 1000 MB</span>
              </p>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area col-12">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                Copyright ©{currentYear} Sitebunker.ro | EXIM HOST SRL |
                RO40672655
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" loading="lazy" />
      </div>
    </footer>
  )
}

export default Footer
