import React, {useState} from "react"
import {useRecoilState} from "recoil"
import {collapsedState} from "../../utils/recoil-atoms"
import {Link} from "gatsby"
import logo from "../../assets/images/logo-sitebunker.png"
import ReactTooltip from "react-tooltip"
import PhoneIcon from "../../assets/svgComponents/phone-icon.svg"
import EmailIcon from "../../assets/svgComponents/email-icon.svg"
import {Helmet} from "react-helmet";

const Navbar = () => {

    const [collapsed, setCollapsed] = useRecoilState(collapsedState)

    const [isActive, setActive] = useState({active: false, id: "", hidden: true})

    const [systemStatus, setSystemStatus] = useState('on');

    const handleToggle = (id) => {
        if (window.innerWidth < 992) {
            let element = document.getElementById(id)
            let elementHidden = element.classList.contains("hideDropdown")
            setActive({active: elementHidden, id: id, hidden: elementHidden})
        }
    }

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }


    // Add sticky class to navbar if the user scroll
    const addStickyNav = () => {

        let elementId = document.getElementById("navbar")
        let topBarId = document.getElementById("top-bar")
        let bodyEl = document.getElementById("gatsby-focus-wrapper")

        if (window.scrollY > 170) {
            elementId.classList.add("is-sticky")
            topBarId.classList.add("is-sticky")
            bodyEl.classList.add("mt-sticky")
        } else {
            elementId.classList.remove("is-sticky")
            topBarId.classList.remove("is-sticky")
            bodyEl.classList.remove("mt-sticky")
        }
    }

    React.useEffect(() => {
        fetch("https://sitebunker-app.herokuapp.com/system-status")
            .then(res => res.json())
            .then(
                (result) => {
                    // let status = result.systemOperational
                    // setOp({ active: status })
                    setSystemStatus(result["SystemStatusSelect"]);

                })


        // Add sticky class
        addStickyNav()

        // Add sticky class on navbar
        document.addEventListener("scroll", () => {
            addStickyNav()
        })


        let element1Link = document.getElementById("domainsLink")
        let element2Link = document.getElementById("hostingLink")
        let element3Link = document.getElementById("aboutLink")
        let element4Link = document.getElementById("serversLink")
        let element5Link = document.getElementById("resellerLink")


        document.addEventListener("click", function (event) {
            if (window.innerWidth < 992) {
                var isClickInside1 = element1Link.contains(event.target)
                var isClickInside2 = element2Link.contains(event.target)
                var isClickInside3 = element3Link.contains(event.target)
                var isClickInside4 = element4Link.contains(event.target)
                var isClickInside5 = element5Link.contains(event.target)


                let element1 = document.getElementById("domainsDropdown")
                let element2 = document.getElementById("hostingDropdown")
                let element3 = document.getElementById("aboutDropdown")
                let element4 = document.getElementById("serversDropdown")
                let element5 = document.getElementById("resellerDropdown")

                if (isClickInside1) {
                    element1.classList.remove("hideDropdown")
                    element1.classList.add("showDropdown")
                }

                if (isClickInside2) {
                    element2.classList.remove("hideDropdown")
                    element2.classList.add("showDropdown")
                }

                if (isClickInside3) {
                    element3.classList.remove("hideDropdown")
                    element3.classList.add("showDropdown")
                }

                if (isClickInside4) {
                    element4.classList.remove("hideDropdown")
                    element4.classList.add("showDropdown")
                }

                if (isClickInside5) {
                    element5.classList.remove("hideDropdown")
                    element5.classList.add("showDropdown")
                }

                if (!isClickInside1) {
                    element1.classList.remove("showDropdown")
                    element1.classList.add("hideDropdown")
                }

                if (!isClickInside2) {
                    element2.classList.remove("showDropdown")
                    element2.classList.add("hideDropdown")
                }

                if (!isClickInside3) {
                    element3.classList.remove("showDropdown")
                    element3.classList.add("hideDropdown")
                }

                if (!isClickInside4) {
                    element4.classList.remove("showDropdown")
                    element4.classList.add("hideDropdown")
                }

                if (!isClickInside5) {
                    element5.classList.remove("showDropdown")
                    element5.classList.add("hideDropdown")
                }
            }
        })
    }, [])

    const classOne = collapsed
        ? "collapse navbar-collapse"
        : "collapse navbar-collapse show"
    const classTwo = collapsed
        ? "navbar-toggler navbar-toggler-right collapsed"
        : "navbar-toggler navbar-toggler-right"

    return (
        <React.Fragment>
            <Helmet>
                <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="71321794-263b-47ab-bbc9-c7b6195a450a" data-blockingmode="auto" type="text/javascript"></script>

                {/* Set default consent to 'denied' */}
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            // Set default consent to 'denied' as a placeholder
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });

            // Function to load Google Tag Manager script after default consent
            function loadGTM() {
                var gtmScript = document.createElement('script');
                gtmScript.async = true;
                gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=G-5QESN2Y8WX";
                gtmScript.onload = function() {
                    // After GTM script is loaded, initialize gtag config
                    gtag('js', new Date());
                    gtag('config', 'G-5QESN2Y8WX');
                    gtag('config', 'AW-868325196');
                };
                document.head.appendChild(gtmScript);
            }
            // Call the function to load Google Tag Manager script after default consent
            loadGTM();
        `}
                </script>
            </Helmet>

            <div id="top-section">
                <div id="top-bar" className="container-fluid">
                    <div className="top-items">
                        {systemStatus === 'on' ?
                            <Link to="/status/" className="mr-5 report-functional d-flex align-items-center top-item"><i
                                className="bx bx-check-circle mr-2"/> Toate sistemele sunt operaționale</Link> :
                            systemStatus === 'off' ?
                                <Link to="/status/"
                                      className="mr-5 report-incident d-flex align-items-center top-item"><i
                                    className="bx bx-error mr-2"/> Incident raportat</Link> :
                                systemStatus === 'maintenance' ?
                                    <Link to="/status/"
                                          className="mr-5 report-maintenance d-flex align-items-center top-item"><i
                                        className="bx bx-loader mr-2"/> Mentenanță programată</Link> : null
                        }
                        <div className="mr-5 top-item">
                            <PhoneIcon className="mr-2"/>
                            Te putem ajuta? <a href="tel:0377101459">0377 101 459</a> | L-V 09:00–17:00
                        </div>
                        <div className="top-item">
                            <EmailIcon className="mr-2"/>
                            <a href="mailto:contact@sitebunker.ro">contact@sitebunker.ro</a> | Program Non-Stop
                        </div>

                    </div>

                </div>
                <div id="navbar" className="navbar-area">
                    <div className="tarn-nav">
                        <div className="container-fluid">

                            <nav className="navbar navbar-expand-lg navbar-light">
                                <Link
                                    to="/"
                                    onClick={() => setCollapsed(true)}
                                    className="navbar-brand"
                                >
                                    <img src={logo} alt="logo" loading="lazy"/>
                                </Link>
                                <div className="mobile-user-button">
                                    <a href="https://sitebunker.ro/client/clientarea.php?language=romanian&currency=2"
                                       target="_blank">
                                        <i className="bx bxs-user-circle"/>
                                    </a>
                                </div>
                                <button
                                    onClick={toggleNavbar}
                                    className={classTwo}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="icon-bar top-bar"/>
                                    <span className="icon-bar middle-bar"/>
                                    <span className="icon-bar bottom-bar"/>
                                </button>

                                <div className={classOne} id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a onClick={() => handleToggle("domainsDropdown")}
                                               className="nav-link initial-cursor"
                                               id="domainsLink"
                                            >
                                                Domenii <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "domainsDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="domainsDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to='/inregistrare-domenii/'
                                                        className="nav-link"
                                                    >Înregistrare Domenii
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to='/transfer-domenii/'
                                                        className="nav-link"
                                                    >Transfer Domenii</Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <Link
                                                to="/gazduire-web-ssd/"
                                                activeClassName="active"
                                                className="nav-link"
                                            >
                                                Găzduire Web
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link initial-cursor"
                                               onClick={() => handleToggle("hostingDropdown")}
                                               id="hostingLink"
                                            >
                                                Găzduire Premium <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "hostingDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="hostingDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/gazduire-wordpress-ssd/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Găzduire WordPress
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/gazduire-prestashop-ssd/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Găzduire PrestaShop
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/gazduire-magento-ssd/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Găzduire Magento
                                                    </Link>
                                                </li>


                                                {/*<li className="nav-item">*/}
                                                {/*    <a*/}
                                                {/*        href="https://sitebunker.ro/client/cart.php?a=add&pid=128&language=romanian&currency=2"*/}
                                                {/*        target="_blank"*/}
                                                {/*        onClick={() => setCollapsed(true)}*/}
                                                {/*        className="nav-link"*/}
                                                {/*    >*/}
                                                {/*        Găzduire Personalizată*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link initial-cursor"
                                               onClick={() => handleToggle("hostingDropdown")}
                                               id="serversLink"
                                            >
                                                Servere <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "serversDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="serversDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/servere-vps/"
                                                        activeClassName="active"
                                                        className="nav-link"
                                                    >
                                                        Servere VPS
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/servere-dedicate/"
                                                        activeClassName="active"
                                                        className="nav-link"
                                                    >
                                                        Servere Dedicate
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to='/colocare-servere/'
                                                        className="nav-link"
                                                    >Colocare Servere</Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <a onClick={() => handleToggle("resellerDropdown")}
                                               className="nav-link initial-cursor"
                                               id="resellerLink"
                                            >
                                                Reseller <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "resellerDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="resellerDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/gazduire-reseller-ssd/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Găzduire Reseller
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <Link
                                                to="/afiliere/"
                                                activeClassName="active"
                                                onClick={() => setCollapsed(true)}
                                                className="nav-link"
                                            >
                                                Afiliere
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link
                                                to="#"
                                                activeClassName="active"
                                                onClick={() => handleToggle("aboutDropdown")}
                                                className="nav-link"
                                                id="aboutLink"
                                            >
                                                Despre noi <i className="bx bx-chevron-down"/>
                                            </Link>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "aboutDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="aboutDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/contact/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Contact
                                                    </Link>
                                                </li>

                                                {/*<li className="nav-item">*/}
                                                {/*    <Link*/}
                                                {/*        to="/parteneri/"*/}
                                                {/*        activeClassName="active"*/}
                                                {/*        onClick={() => setCollapsed(true)}*/}
                                                {/*        className="nav-link"*/}
                                                {/*    >*/}
                                                {/*        Parteneri*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}

                                                <li className="nav-item">
                                                    <Link
                                                        to="/blog/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Blog
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/achizitii-seap/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        SICAP
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <div className="others-option d-flex align-items-center">
                                        <div className="service-status mr-3">
                                            <Link
                                                to="/status/"
                                                className="nav-link heartbeat"
                                                data-for="services-status"
                                                data-tip="Status Servicii"
                                                data-text-color="white"
                                                data-background-color="#17992C"
                                                data-place="bottom"
                                                data-effect="solid"
                                            >
                                                <i className="bx bxs-server"/>
                                            </Link>
                                            <ReactTooltip className="services-status-tooltip" id="services-status"/>
                                        </div>
                                        {/*<div className="language-switcher mr-3">*/}
                                        {/*  <a href="https://sitebunker.net" target="_blank">*/}
                                        {/*    <img src={enFlag} loading="lazy" />*/}
                                        {/*  </a>*/}
                                        {/*</div>*/}
                                        <div className="user-button mr-3">
                                            <a className="my-account"
                                               href="https://sitebunker.ro/client/clientarea.php?language=romanian&currency=2"
                                               target="_blank">
                                                <i className="bx bxs-user-circle"/>
                                                <span>Contul meu</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Navbar
